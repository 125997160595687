.mainContent {
    display: block;
    width: 90%;
    margin: auto;
}

.leftAligned {
    text-align: left;
}

#configBtn {
    margin-left: auto;
    margin-right: auto;
    text-transform: none;
    background-color: rgb(255, 166, 0);
}

@media screen and (max-width: 800px) {
    #configBtn {
        float: right;
    }
}

@media screen and (max-width: 600px) {
    #configBtn {
        float: none;
        margin-top: 100px;
    }
}

#configBtn svg {
    margin-bottom: -2px;
}

@media screen and (min-width: 799px) {
    #configBtn {
        position: absolute;
        right: 20px;
    }
}

.inlineBtn {
    display: inline-block;
    padding: 5px;
}

.active {
    font-style: italic;
    font-size: 110%;
    color: green;
}

.inactive {
    font-style: italic;
    font-size: 110%;
    color: var(--main-red-color);
}

.label {
    display: block;
    text-align: left;
    color: var(--main-muted-text-color);
}

.dueDate {
    padding: 0px 5px;
}

.materialContainer {
    padding: 20px;
    text-align: center;
    margin: 10px auto;
}

.contact {
    padding: 5px 0px;
}

#addIcon {
    color: green;
    font-size: 0.9em;
}

#trashIcon {
  color: var(--main-red-color);
}