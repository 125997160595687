.input, .TextArea {
    color: #444;
    font-family: 'Montserrat', sans-serif;
    font-size: 120%;
    padding: 8px 10px;
    border: 3px solid rgb(153, 153, 153);
    border-radius: 15px;
    margin: 10px;
}

.TextArea {
    width: 80%;
}

@media screen and (max-width: 800px) {
    .input, .TextArea {
        width: 90%;
    }
}

input:focus {
    border: 3px solid rgb(113, 151, 255);
}

.inputBlock {
    display: block;
    width: 100%;
}

.invalid {
    border: 3px solid red;
}

.error {
    color: red;
    font: sans-serif;
    margin: auto;
    font-size: 110%;
    display: block;
    text-align: center;
    padding: 5px;
}

.labeledInput {
    display: block;
    text-align: left;
    border: none;
    border-bottom: 2px solid #888;
    background-color: #d4d4d4;
    padding: 8px;
    width: 95%;
    transition: 0.3s background-color;
}

.labeledInput:focus {
    border: none;
    border-bottom: 2px solid #888;
    background-color: #b6e4ff;
}

.label {
    display: block;
    text-align: left;
    color: var(--main-muted-text-color);
}


.customSelect {
    border: none;
    border-bottom: 2px solid #888;
    background-color: #d4d4d4;
    padding: 8px;
    transition: 0.3s background-color;
}

.selectOption {
    border-bottom: 2px solid #888;
    padding: 8px;
}

.checkbox {
    color: var(--main-text-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 120%;
    padding: 8px 10px;
    border: 3px solid rgb(153, 153, 153);
    border-radius: 15px;
    margin: 10px;
}

@media screen and (prefers-color-scheme: dark) {
    .checkbox {
        border: 3px solid #bbb;
        background-color: #444;
    }

    .checkbox::placeholder {
        color: var(--main-text-color);
        opacity: 0.8;
        font-style: italic;
    }
}

.checkbox:focus {
    border: 3px solid rgb(113, 151, 255);
}

.checkboxContainer {
    display: inline-block;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}