.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-image: radial-gradient(white, yellow);
    font-family: 'Montserrat', sans-serif;
}

.title {
    text-align: center;
    font-size: 250%;
    margin-top: 30vh;
}

.loading {
    text-align: center;
    font-size: 200%;
}

.Spinner {
    display: block;
    width: 50%;
    margin: 20px auto;
}

@media screen and (prefers-color-scheme: dark) {
    .backdrop {
        background-image: radial-gradient(hsl(55deg, 100%, 30%), #222);
    }
}