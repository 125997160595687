.mainContent {
    margin-top: 120px;
    position: relative;
}

.title {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.Courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width: 1000px) {
    .Courses {
        /*justify-content: center; */
         justify-content: flex-start; 
    }
}

#NewCourseButton {
    margin-left: auto;
    margin-right: auto;
    font-size: 110%;
    background-color: rgb(82, 189, 21);
}

@media screen and (min-width: 1000px) {
    #NewCourseButton {
        position: absolute;
        top: 10px;
        right: 50px;
    }
}

@media screen and (min-width: 1300px) {
    #NewCourseButton {
        right: 100px;
    }
}

.Spinner {
    text-align: center;
}

.NewCourseForm {
    text-align: center;
}