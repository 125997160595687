.mainContent {
  width: 90%;
  margin: auto;
  padding: 20px 0px;
}

.label {
    display: block;
    text-align: left;
    color: var(--main-muted-text-color);
}

#addIcon {
    color: green;
    font-size: 0.8em;
}

#trashIcon {
  color: var(--main-red-color);
}

.verified {
  color: green;
}

.pending {
  color: orange;
}

.verifyBtn {
  color: blue;
  text-decoration: underline;
}

.verifyBtn:hover {
  filter: brightness(70%);
  cursor: pointer;
}

.verifyStatus {
  font-style: italic;
  float: right;
  padding: 0px 5px;
}

.selectContainer {
    display: block;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.contactContainer {
  display: block;
  width: 85%;
}

.inlineBtn {
  display: inline-block;
  padding: 5px;
}

@media (prefers-reduced-motion: no-preference) {
    .spinner {
      animation: loading-spinner-spin infinite 2s linear;
    }
  }

  @keyframes loading-spinner-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }