.Card {
    margin: 20px;
    width: 90%;
    display: block;
    background-color: rgb(255, 73, 73);
    border-radius: 8px;
    box-shadow: 2px 2px 5px #a0a0a0;
    background: linear-gradient( to top, rgba(0,0,0,0.1), rgba(255,255,255,0.3));
    min-height: 200px;
    max-height: 350px;
    overflow: hidden;
    transition: 0.2s box-shadow, 0.2s background;
    position: relative;
}

.Card:hover {
    box-shadow: 5px 5px 10px #808080;
    cursor: pointer;
    background: linear-gradient( to top, rgba(0,0,0,0.3), rgba(255,255,255,0.3));
}

@media screen and (prefers-color-scheme: dark) {
    .Card {
        box-shadow: 2px 2px 5px #080808;
        background: linear-gradient( to top, rgba(0,0,0,0.3), rgba(255,255,255,0.5));
    }
    .Card:hover {
        box-shadow: 5px 5px 10px #000000;
    }
}

@media screen and (min-width: 600px) {
    .Card {
        min-height: 200px;
        max-height: 450px;
    }
}

@media screen and (min-width: 1000px) {
    .Card {
        min-height: 300px;
        max-height: 500px;
        width: 45%;
    }
}

@media screen and (min-width: 1600px) {
    .Card {
        min-height: 300px;
        max-height: 600px;
        width: 30%;
    }
}

.CardTop {
    padding: 15px 20px;
    background-color: rgba(255,255,255,0.2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    /*max-height: 20%;
    display: block;
    overflow: hidden;*/
}

.CardMiddle {
    padding: 20px;
    overflow: hidden;
    margin-bottom: 20px;
}

.CardBottom {
    padding: 10px 20px;
    margin: 0px;
    background-color: yellow;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    color: red;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

@media screen and (prefers-color-scheme: dark) {
    .CardBottom {
        background-color: rgb(148, 5, 0);
        color: #eee;
        font-weight: bold;
    }
}

.Title {
    font-weight: bold;
    display: block;
    font-size: 110%;
    font-family: 'Montserrat', sans-serif;
    color: #333;
    margin-right: 20%;
}

.CourseStatus {
    display: block;
    float: right;
    color: #666;
    font-family: 'Montserrat', sans-serif;
}

.active {
    color: green;
    font-weight: bold;
}

.description {
    color: #222;
}