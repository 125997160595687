.message {
    font: sans-serif;
    font-size: 120%;
    display: block;
    padding: 5px;
    margin: none;
}

#success {
    color: green;
}

#fail {
    color: red;
}