.invalid {
    border: 3px solid red;
}

.valid {
    border: 3px solid green;
}

.button {
    color: white;
    font-size: 120%;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    padding: 8px 10px;
    background-color: rgb(113, 151, 255);
    border: none;
    border-radius: 15px;
}

.button:hover {
    background-color: rgb(70, 97, 170);
}

.displayBlock {
    display: block;
}

.spinner {
    width: 25px;
    height: 25px;
    pointer-events: none;
}

.error {
    color: red;
    font: sans-serif;
    font-size: 120%;
    display: block;
    padding: 5px;
    margin: none;
}

.emailResponse {
    display: flex;
    flex-flow: row;
}

.emailResponse h4 {
    text-align: center;
}

.successEmails {
    text-align: left;
    background-color: rgba(0,255,0,0.3);
    padding: 5px;
    border-radius: 15px;
    flex: 1;
    margin: 0px 5px;
}

.failedEmails {
    text-align: left;
    background-color: rgba(255,0,0,0.3);
    padding: 5px;
    border-radius: 15px;
    flex: 1;
    margin: 0px 5px;
}
