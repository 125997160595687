.btnToShow {
    opacity: 1;
    float: right;
}

.container {
    display: block;
    border-radius: 5px;
    text-align: left;
    padding: 12px;
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        margin: 5px 0px;
        border: 2px solid rgba(0,0,0,0.4);
    }
}

.hoverContainer:hover {
    background-color: var(--main-header-color);
    box-shadow: 0.5px 0.5px 5px;
}

.clickable:hover {
    cursor: pointer;
}

@media screen and (prefers-color-scheme: dark) {
    .hoverContainer:hover {
        background-color: var(--main-header-color);
        box-shadow: 0.5px 0.5px 5px #222;
    }
}

@media screen and (min-width: 800px) {
    .btnToShow {
        opacity: 0;
        float: right;
    }
}

.hoverContainer:hover .btnToShow {
    opacity: 1;
}

.disabled {
    filter: opacity(50%);
}

#specialIcon {
    color: rgb(216, 216, 0);
    padding-right: 5px;
    margin-right: 3px;
    display: inline-block;
}

@media screen and (prefers-color-scheme: dark) {
    #specialIcon {
        color: rgb(235, 235, 0);
    }
}