.Popup {
    left: 5%;
    border: 2px solid #ddd;
    border-radius: 10px;
    z-index: 1000;
    width: 90%;
    background-color: var(--main-bg-color);
    box-shadow: 4px 4px 5px #222;
    position: fixed;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media screen and (min-width: 799px) {
    .Popup {
        left: 25%;
        width: 50%;
    }
}

@media screen and (prefers-color-scheme: dark) {
    .Popup {
        border: 2px solid #555;
        box-shadow: none;
    }
}

.TitleBar {
    background-color: var(--main-header-color);
    margin: 0px;
    padding: 15px;
    border-bottom: 1px solid var(--main-header-border-color);
    box-shadow: 2px 2px 5px var(--main-header-border-color);
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.TitleBar h2 {
    padding: 0px;
    margin: 0px;
}

@media screen and (prefers-color-scheme: dark) {
    .TitleBar {
        background-color: var(--main-header-color);
        border-bottom: 1px solid var(--main-header-border-color);
        box-shadow: none;
    }
}

#CloseIcon {
   float: right;
   font-size: 170%;
   padding: 3px;
}

#CloseIcon:hover {
    background-color: rgb(238, 124, 48);
    border-radius: 50%;
    color: #eee;
}

.Content {
    padding: 10px 40px 30px;
    margin: auto;
    overflow-y: auto;
}

.FixedContentHeight {
    height: 50vh;
}

.DynamicContentHeight {
    max-height: 50vh;
}

.BottomControls {
    box-shadow: -2px -2px 2px var(--main-header-border-color);
    margin: 0px;
    padding: 15px;
    text-align: center;
}
