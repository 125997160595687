.h1 {
    margin-top: 100px;
    text-align: center;
}

.SpinnerCenter {
    margin: 10px auto 10px auto;
    width: 100px;
}

.center {
    text-align: center;
}

p:last-of-type {
    margin-bottom: 20px;
}