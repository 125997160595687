.loginBox {
    width: 100%;
    height: 90vh;
    margin-top: 50%;
    text-align: center;
}

.title {
    font-family: 'Montserrat', sans-serif;
    color: var(--main-title-color);
    font-weight: bold;
}

.input {
    color: var(--main-text-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 120%;
    padding: 8px 10px;
    border: 3px solid rgb(153, 153, 153);
    border-radius: 15px;
    margin: 10px;
}

@media screen and (prefers-color-scheme: dark) {
    .input {
        border: 3px solid #bbb;
        background-color: #444;
    }

    .input::placeholder {
        color: var(--main-text-color);
        opacity: 0.8;
        font-style: italic;
    }
}

input:focus {
    border: 3px solid rgb(113, 151, 255);
}

.invalid {
    border: 3px solid red;
}

.valid {
    border: 3px solid green;
}

.button {
    color: white;
    font-size: 120%;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    padding: 8px 10px;
    background-color: rgb(113, 151, 255);
    border: none;
    border-radius: 15px;
}

.button:hover {
    background-color: rgb(70, 97, 170);
}

.displayBlock {
    display: block;
}

.spinner {
    width: 25px;
    height: 25px;
    pointer-events: none;
}

.error {
    color: red;
    font: sans-serif;
    font-size: 120%;
    display: block;
    padding: 5px;
    margin: none;
}

@media screen and (min-width: 799px) {
    .loginBox {
        margin-top: 20%;
    }
}


@media screen and (min-width: 1500px) {
    .loginBox {
        margin-top: 10%;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .spinner {
      animation: loading-spinner-spin infinite 2s linear;
    }
  }

  @keyframes loading-spinner-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }