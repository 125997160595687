.expandBtn {
    display: inline-block;
    padding: 0px 5px;
    font-size: 110%;
    cursor: pointer;
}

.label {

}

.header {
    background-color: var(--main-header-color);
    border-radius: 5px;
    padding: 10px 0px 10px 10px;
    text-align: left;
}

.container {
    margin: 10px 0px;
}

.mainContent {
    margin-left: 40px;
    padding: 15px 0px;
    text-align: left;
    transition: display 2s;
}

.expanded {
    display: block;
}

.closed {
    display: none;
}

.bottomBorder {
    border-bottom: 2px solid rgb(153, 153, 153);
}