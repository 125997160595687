.Header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    font-family: 'Montserrat', sans-serif;
}

/* Add a black background color to the top navigation */
.topnav {
    background-color: var(--main-header-color);
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid var(--main-header-border-color);
    box-shadow: 2px 2px 5px var(--main-header-border-color);
    box-sizing: border-box;
    z-index: 9999;

  }

  @media screen and (prefers-color-scheme: dark) {
    .topnav {
      background-color: var(--main-header-color);
      border-bottom: 1px solid var(--main-header-border-color);
      box-shadow: none;
    }
  }
  
  /* Style the links inside the navigation bar */
  .topnav a,.topnav a:active, .topnav a:visited, .topnav .LogoutButton {
    float: right;
    display: block;
    color: var(--main-text-colour);
    padding: 15px;
    font-size: 120%;
    text-decoration: none;
    background-color: rgba(0,0,0,0);
    border: none;
    margin: 0px;
    font-family: 'Montserrat', sans-serif;
  }

  @media screen and (prefers-color-scheme: dark) {
    .topnav a,.topnav a:active, .topnav a:visited, .LogoutButton {
      color: #ddd;
    }
  }

  .topnav a:first-of-type {
      border: none;
  }
  
  .topnav .title {
      font-weight: bold;
      position: fixed;

  }

  .topnav .title:hover {
    background-color: rgba(0,0,0,0);
}

  @media screen and (max-width: 799px) {
    .topnav .title, .topnav .title:focus, .topnav .title:active {
      position: relative;
      float: none;
      width: 50%;
    }
  }
  
  /* Change the color of links on hover */
  .topnav a:hover, .topnav .LogoutButton:hover {
    margin: 0px;
    padding: 15px;
    background-color: rgba(0,0,0,0.2);
    cursor: pointer;
  }

  @media screen and (prefers-color-scheme: dark) {
    .topnav a:hover {
      background-color: rgba(0,0,0,0.2);
    }
  }
  
  
  /* Hide the link that should open and close the topnav on small screens */
  .topnav .icon {
    display: none;
    font-size: 250%;
    background-color: rgba(0,0,0,0);
    border: none;
    color: #333;
    /*margin: 25px 20px 15px 10px;*/
    position: fixed;
    top: 23px;
    right: 20px;
  }

  @media screen and (prefers-color-scheme: dark) {
    .topnav .icon {
      color: #ddd;
    }
  }

  .topnav .icon:hover {
      cursor: pointer;
  }

  
  
  /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
  @media screen and (max-width: 799px) {
    .topnav a:not(:first-child), .topnav .LogoutButton {display: none; height: 0px;}
    .topnav .icon {
        display: block;
        padding: 0px;
    }
  }
  
  /* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
  @media screen and (max-width: 799px) {

  }

  .Header .MobileMenu {
    display: none;
    background-color: var(--main-bg-color);
    max-height: 0px;
    display: block;
    width: 100%;
    overflow: hidden;
    transition: 0.3s max-height ease-out;
    z-index: 998;
  }

  .MobileMenu.responsive {
    display: block;
    max-height: 200px;
    overflow-y: auto;
  }

  .MobileMenu a, .MobileMenu a:active, .MobileMenu a:visited, .MobileMenu .LogoutButton {
    display: block;
    padding: 10px;
    float: none;
    text-align: right;
    width: 100%;
    margin: 0px;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 140%;
    text-decoration: none;
    background-color: rgba(0,0,0,0);
    border: none;
    color: var(--main-text-colour);
  }


  /* ---------- BANNER --------------- */

  .Banner {
    display: block;
    padding: 15px;
    background-color: rgba(0,0,0,0);
    text-align: center;
    font-weight: bold;
    margin-top: -30px;
    transition: 0.5s background-color, 0.5s margin-top;
  }

  .Open {
    background-color: green;
    margin-top: 0px
  }

  .BannerClose {
    float: right;
  }

  .BannerButton a {
    border: none;
    color: var(--main-text-colour) !important;
    font-weight: bold;
    margin-left: 20px;
  }

  .Banner.BannerInfo {
    background-color: rgb(106, 153, 255);
  }

  .Banner.BannerSuccess {
    background-color: rgb(54, 177, 54);
  }

  .Banner.BannerError {
    background-color: rgb(255, 97, 97);
  }

  @media screen and (prefers-color-scheme: dark) {
    .Banner.BannerInfo {
      background-color: rgb(58, 91, 240);
    }
  
    .Banner.BannerSuccess {
      background-color: rgb(9, 168, 9);
    }
  
    .Banner.BannerError {
      background-color: rgb(197, 51, 51);
    }
  }

