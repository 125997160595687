:root {
  --main-bg-color: #fff;
  --main-title-color: #555;
  --main-text-color: #333;
  --main-muted-text-color: #555;
  --active-link-color: rgb(22, 118, 228);
  --main-header-color: #ddd;
  --main-header-border-color: #c8c8c8;
  --main-red-color: red;
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: #1f2225;
    --main-title-color: #ccc;
    --main-text-color: #eee;
    --main-muted-text-color: #ccc;
    --active-link-color: rgb(110, 172, 253);
    --main-header-color: #555;
    --main-header-border-color: #333333;
    --main-red-color: rgb(235, 57, 57);
  }
}

.html {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin-top: 50px;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
}

a {
  color: var(--active-link-color);
}

a:active {
  color: var(--active-link-color);
}

a:visited {
  color: var(--active-link-color);
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
}