.btn {
    display: block;
    width: auto;
    color: white;
    font-size: 120%;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    padding: 8px 10px;
    margin: 5px auto;
    background-color: rgb(82, 189, 21);/*rgb(113, 151, 255);*/
    border: none;
    border-radius: 15px;
}

#cancelBtn {
    background-color: var(--main-bg-color);
    padding: 7px 9px;
    border: 1.5px solid var(--main-red-color);
    color: var(--main-red-color);
}

.iconBtn {
    border: none;
    display: inline-block;
    padding: 0px 5px;
    margin: 0px 3px;
    background-color: transparent;
    font-size: 120%;
    color: rgb(94, 94, 94);
}
@media screen and (prefers-color-scheme: dark) {
    .iconBtn {
        color: rgb(179, 179, 179);
    }
}

.enabledBtn:hover {
    filter: brightness(70%);
    cursor: pointer;
}

.disabledBtn {
    filter: opacity(50%);
}