.Sidebar {
    height: 100vh;
    background-color: rgb(202, 202, 202);
    /*float: left; */
    z-index: 97;
    position: fixed;
    top: 0;
    left: 0;
    padding: 100px 0px 100px 0px;
    width: 100vw;
    transition: 0.3s width;
}

@media screen and (prefers-color-scheme: dark) {
    .Sidebar {
        background-color: #444;
    }
}

.isOpen {
    width: 100vw;
}

.isClosed {
    width: 0px;
}

.Sidebar h2 {
    display: block;
    color: var(--main-muted-text-colour);
    font-size: 160%;
    padding: 0px 30px 10px 20px;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    text-align: center;
}

.isClosed h2 {
    display: none;
}

.Sidebar a, .Sidebar a:visited, .Sidebar a:active {
    display: block;
    color: var(--main-text-colour);
    text-decoration: none;
    font-size: 140%;
    padding: 10px 35px;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
}

.isClosed * {
    display: none;
}

.isClosed a {
    display: none;
}

.Sidebar a:hover {
    background-color: rgba(120, 120, 120, 0.5);
}

#openDrawerButton {
    margin-top: 10px;
    float: left;
    color: var(--active-link-color)/*rgb(47, 47, 189)*/;
}

@media screen and (min-width: 800px) {
    .Sidebar {
        width: 300px;
    }
    
    .isOpen {
        width: 300px;
    }
    
    .isClosed {
        width: 300px;
    }
    .isClosed h2 {
        display: block;
    }
    .isClosed a {
        display: block;
    }

    .isClosed * {
        display: initial;
    }
    
}
#dropdownLink {
    margin: 0px;
}

#dropdownLinkContent {
    padding: 0px;
    margin-left: 28px;
    font-size: 90%;
}

#dropdownLinkLabel {
    display: inline-block;
    padding-left: 7px;
    padding-right: unset;
    margin-right: 0px;
}

.MainContent {
    text-align: center;
    padding: 0px 0px 50px 0px;
    margin-top: 100px;
}

.MainContent h1 {
    font-family: 'Montserrat', sans-serif;
    color: var(--page-title);
}

@media screen and (min-width: 800px) {
    .MainContent {
        margin-left: 300px;
        padding: 0px 50px;
    }
}