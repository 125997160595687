.container {
    display: block;
    width: 100%;
    margin: 10px auto;
    border: 2px solid var(--main-header-color);
}
.tabsContainer {
    display: flex;
    flex-direction: row;
    background-color: var(--main-header-color);
    padding: 10px 10px 0px;
    margin: auto;
}

.tab {
    display: inline-block;
    flex: 1;
    margin: 0px;
    border: none;
    padding: 10px 0px;
    text-align: center;
}

#inactive {
    background-color: var(--main-header-color);
    border-right: 1px solid rgb(153, 153, 153);
    border-left: 1px solid rgb(153, 153, 153);
}

#inactive:hover {
    filter: brightness(105%);
    border-right: 1px solid var(--main-header-color);
    border-left: 1px solid var(--main-header-color);
    border-radius: 8px 8px 0px 0px;
}

#active {
    background-color: var(--main-bg-color);
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid var(--main-bg-color);
}

.mainContent {
    display: block;
    width: 90%;
    text-align: center;
    padding: 10px;
    margin: auto;
}